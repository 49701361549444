import React, { useState, useEffect } from "react"
import { Button, Modal } from "react-bootstrap"
// import Cookies from "universal-cookie"
import WebriQForm from "@webriq/gatsby-webriq-form"
import FlowChart from "@images/LinkedIn-Prospection-Action-Flow.jpg"
// const cookies = new Cookies()

const PopUp = () => {
  const [show, setShow] = useState(false)
  // const [count, setCount] = useState(0)
  // const [cookie, setCookie] = useState("popup")

  const handleClose = () => setShow(false)
  // const handleShow = () => setShow(true)

  useEffect(() => {
    // const getCookie = cookies.get('cookiename')
    const timer = setTimeout(() => {
      // cookies.set('cookiename', cookie, { path: '/' });
      // if(getCookie !== cookie) {
      //   setShow(true)
      // }
      // else {
      //   setShow(false)
      // }
      setShow(true)
    }, 5000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <Modal dialogClassName="" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <button className="close-modal" onClick={handleClose}>
            <i className="fa fa-close" />
          </button>
          <div className="row no-gutters align-items-center">
            <div className="col-md-6">
              <div className="p-3 text-center">
                <img
                  className="img-fluid mb-4"
                  src={FlowChart}
                  alt="FlowChart"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="p-5 bg-light">
                <h3 className="mb-3 text-primary-gradient">
                  Download Thesis and Execution Diagrams
                </h3>
                <p>
                  Links To All Diagrams And Written Thesis Sent To Your Mailbox
                  Within 10 Seconds
                </p>
                <WebriQForm
                  method="POST"
                  data-form-id="67772daa-588a-4247-b79e-9acad747340f"
                  name="Newsletter Form"
                  className="formStyle"
                >
                  <div className="form-group">
                    <label
                      htmlFor="nameInput"
                      id="nameInput"
                      className="d-block"
                    >
                      Name
                      <input
                        className="w-100 form-control form-control-lg"
                        type="text"
                        name="name"
                        id="nameInput"
                        placeHolder="Jane Doe"
                      />
                    </label>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="emailInput"
                      id="emailInput"
                      className="d-block"
                    >
                      Email
                      <input
                        className="w-100 form-control form-control-lg"
                        type="email"
                        name="email"
                        id="emailInput"
                        placeHolder="yourname@email.com"
                      />
                    </label>
                  </div>
                  <div className="form-group mt-4">
                    <Button variant="primary" size="lg" type="submit">
                      Download Now
                    </Button>
                  </div>
                </WebriQForm>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*<Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-0" closeButton />
        <Modal.Body className="px-5 pt-3 pb-5">
          <img className="img-fluid mb-4" src={FlowChart} alt="FlowChart" />
          <h3 className="mb-3 text-primary-gradient">
            Download Thesis and Execution Diagrams
          </h3>
          <p>
            Links To All Diagrams And Written Thesis Sent To Your Mailbox Within
            10 Seconds
          </p>
          <WebriQForm
            method="POST"
            data-form-id="67772daa-588a-4247-b79e-9acad747340f"
            name="Newsletter Form"
            className="formStyle"
          >
            <div className="form-group">
              <label htmlFor="nameInput" id="nameInput" className="d-block">
                Name
                <input
                  className="w-100 form-control form-control-lg"
                  type="text"
                  name="name"
                  id="nameInput"
                  placeHolder="Jane Doe"
                />
              </label>
            </div>
            <div className="form-group">
              <label htmlFor="emailInput" id="emailInput" className="d-block">
                Email
                <input
                  className="w-100 form-control form-control-lg"
                  type="email"
                  name="email"
                  id="emailInput"
                  placeHolder="yourname@email.com"
                />
              </label>
            </div>
            <div className="form-group mt-4">
              <Button variant="primary" size="lg" type="submit">
                Download Now
              </Button>
            </div>
          </WebriQForm>
        </Modal.Body>
      </Modal>*/}
    </>
  )
}

export default PopUp
